<template>
<div style="width:100%">
  <v-sheet :color="colores.primario" style="padding:1px" rounded v-if="registro.gessolicitante">
    <v-card flat>
      <v-toolbar dense flat><span :style="{'color': colores.primario}" style="font-size:20px"><b>SOLICITANTE</b></span></v-toolbar>
      <v-card-text class="pt-0">
        <v-row no-gutters>
          <v-col cols="12" md="6" class="pr-1">
            <v-autocomplete v-model="form_solicitante.tipo_id" :rules="[rules.requerido]" :items="items.tiposolicitantes" :loading="loadingData" item-text="descripcion" item-value="id" :color="colores.primario" label="TIPO DE SOLICITANTE *"></v-autocomplete>
          </v-col>
          <v-col cols="12" md="6">
            <texto2 :colores="colores" :titulo="'NOMBRE COMPLETO DE LA PERSONA QUE RADICA'" :descripcion="registro.gessolicitante.nombre"></texto2>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="12" md="6" class="pr-1">
            <texto2 :colores="colores" :titulo="'TELÉFONO / CELULAR'" :descripcion="registro.gessolicitante.telefono"></texto2>
          </v-col>
          <v-col cols="12" md="6">
            <texto2 :colores="colores" :titulo="'CORREO ELECTRÓNICO DE LA PERSONA QUE RADICA'" :descripcion="registro.gessolicitante.correo"></texto2>
          </v-col>
        </v-row>
        <v-row no-gutters v-if="registro.gessolicitante.direccion != null">
          <v-col cols="12" md="6" class="pr-1">
            <texto2 :colores="colores" :titulo="'DIRECCIÓN'" :descripcion="registro.gessolicitante.direccion.replaceAll(':::', ' / ')"></texto2>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-sheet>
</div>
</template>

<script>
export default {
  name: 'sfc_form_solicitanteComponent',
  components: {
    texto2: () => import('@/components/widgets/text2Component.vue')
  },
  props: ['colores', 'registro', 'form_solicitante', 'rules', 'items', 'loadingData'],
  data: () => ({
  }),
  created () {

  },
  methods: {

  }

}
</script>
